import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../colors';

const StyledMain = styled.main`
  color: ${colors.primary};
  font-family: scandia-line-web, sans-serif, serif;
  background-color: #000;
`;

const StyledMenu = styled.div`
  position: fixed;
  z-index: 100;
  text-align: center;
  width: 100vw;
  background: linear-gradient(180deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%);
`;

const StyledFooterContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 20px;
  right: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? '30px' : '50px')};
  text-align: center;
  z-index: 2;
  .faded-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);;
    height: 50px;
    width: 100vw;
    z-index: -1;
  }
`;

const GlobalStyles = css`
  & .fp-watermark {
    display: none
  }
`;

export {
  StyledMain, StyledMenu, StyledFooterContainer, GlobalStyles,
};
