import {
  useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { StylesButton } from './index.styles';

type Props = {
  children: string,
  href: string
};

export function MainButton({ children, href } : Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StylesButton
      variant="outlined"
      href={href}
      size={isMobile ? 'large' : 'medium'}
    >
      {children}
    </StylesButton>
  );
}
