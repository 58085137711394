import styled from '@emotion/styled';
import { StyledType } from '../types.d';

const StyledWorkBoxWrapper = styled.div`
  display: ${(props: StyledType) => (props.matches ? 'flex' : 'block')};
  justify-content: center;
`;

const StyledWorkBox = styled.div`
  margin:${(props : StyledType) => (props.matches ? '10px' : '10px 10px 10px 100px')};
  p {
    font-size: 24px;
    font-weight: 400
  }
  .headline {
    display: flex;      
  }
  .icon {
    width: 45px;
  }
  path {
    stroke:#fff;
    fill:#fff;
  }
`;

export { StyledWorkBoxWrapper, StyledWorkBox };
