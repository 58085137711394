import {
  Container, Grid, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import { useIntl } from 'react-intl';
import Logo from '../../images/svg/logo.svg';
import { MainButton } from '../main-button';
import H1 from '../typo/h1';
import { blurIn } from '../../style/animations/blur-in';

const StyledLogo = styled.div`
  margin-bottom: 30px;
  z-index: 1;
`;

const StyledContainer = styled.div`
  z-index: 1;
  text-align: center;
`;

type Props = {
  isInView: boolean
};

function Opener({ isInView }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { formatMessage } = useIntl();

  const { filter } = useSpring(blurIn(isInView));

  return (
    <StyledContainer>
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={5} sm={8}>
            <StyledLogo>
              <Logo />
            </StyledLogo>
          </Grid>
        </Grid>
        {isMobile ? (
          <H1>
            {formatMessage({ id: 'headline-main' })}
          </H1>
        ) : (
          <animated.div style={{ filter }}>
            <H1>
              {formatMessage({ id: 'headline-main-mobile' })}
            </H1>
          </animated.div>
        )}

        <Container>
          <MainButton href="mailto:hello@polymodal.de">
            {formatMessage({ id: 'hello-cta-main' })}
          </MainButton>
        </Container>
      </Container>
    </StyledContainer>
  );
}

export default Opener;
