import styled from '@emotion/styled';

const StyledMainMenu = styled.ul`
  text-decoration: none;
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: center;
  flex-direction: ${({ isMobile }: { isMobile: Boolean }) : string => (isMobile ? 'column' : 'row')};
  width: 100%;
  text-align: center;
`;

const StyledMenu = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export { StyledMainMenu, StyledMenu };
