import styled from '@emotion/styled';
import { colors } from '../../style/colors';

const StyledListItem = styled.li`
  margin-inline: 10px;
  min-width: 100px;
`;

const StyledMenuItem = styled.a`
  text-decoration: none;
  color: ${colors.primary};
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  display: flex;
  height: 40px;
  margin-top: 25px;
  justify-content: center;
`;

export { StyledListItem, StyledMenuItem };
