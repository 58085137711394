import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { Fragment, useState } from 'react';
import Hamburger from 'hamburger-react';
import { useIntl } from 'react-intl';
import { menuItems } from '../../data/menu';
import MenuItem from './menu-item';
import { StyledMainMenu, StyledMenu } from './index.styles';

function MainMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mobileAndOpen = isMobile && menuOpen;
  const { formatMessage } = useIntl();

  return (
    <nav>
      {isMobile && (
        <StyledMenu>
          <Hamburger toggled={menuOpen} toggle={setMenuOpen} />
        </StyledMenu>
      )}
      <Grid container justifyContent="center">
        <Grid item>
          <StyledMainMenu isMobile={isMobile}>
            {(!(isMobile) || (mobileAndOpen))
            && menuItems.map(({ shortname, longname, anchor }) => (
              <Fragment key={shortname}>
                <MenuItem
                  shortname={shortname}
                  longname={formatMessage({ id: longname })}
                  anchor={anchor}
                />
              </Fragment>
            ))}
          </StyledMainMenu>
        </Grid>
      </Grid>
    </nav>
  );
}

export default MainMenu;
