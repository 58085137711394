import React from 'react';
import {
  Container, Grid, styled,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { workItems } from '../../data/work';
import WorkBox from './work-box';
import H2 from '../typo/h2';
import { ViewProps } from './types.d';

const StyledGridItem = styled(Grid)({
  fontSize: 40,
  fontWeight: 600,
  wordWrap: 'break-word',
  lineHeight: 1,
});

function Work({ isInView } : ViewProps) {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Grid container justifyContent="center" mb={5}>
        <H2>{formatMessage({ id: 'work-headline' })}</H2>
      </Grid>
      <Grid
        container
        justifyContent="center"
      >
        {workItems.map(({ name, points, icon }) => (
          <StyledGridItem
            key={name}
            item
            xs={12}
            md={4}
          >
            <WorkBox
              name={name}
              icon={icon}
              points={points}
              isInView={isInView}
            />
          </StyledGridItem>
        ))}
      </Grid>
    </Container>
  );
}

export default Work;
