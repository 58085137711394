import * as React from 'react';
import { IntlProvider } from 'react-intl';
import SEO from '../components/seo';
import Home from '../components/home';
import { Languages } from '../custom.d';
import de from '../data/lang/de';
import en from '../data/lang/en';
import cn from '../data/lang/cn';

function IndexPage() {
  const [currentLang, setCurrentLang] = React.useState<Languages>(Languages.EN);
  const changeLanguage = (lang: Languages) => {
    setCurrentLang(lang);
  };

  const messages = () => {
    switch (currentLang) {
      case Languages.DE:
        return de;
      case Languages.CN:
        return cn;
      case Languages.EN:
      default:
        return en;
    }
  };

  return (
    <IntlProvider messages={messages()} locale={currentLang} defaultLocale={Languages.EN}>
      <Home changeLanguage={changeLanguage} currentLang={currentLang} />
    </IntlProvider>
  );
}

export default IndexPage;

export function Head() {
  return (
    <SEO />
  );
}
