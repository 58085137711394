import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  useSpring, animated,
} from 'react-spring';
import { useIntl } from 'react-intl';
import { WorkProps } from '../types.d';
import { StyledWorkBox, StyledWorkBoxWrapper } from './index.styles';
import { blurIn } from '../../../style/animations/blur-in';

function WorkBox({
  name, icon, points, isInView,
} : WorkProps) {
  const Icon = icon;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { formatMessage } = useIntl();

  const { filter } = useSpring(blurIn(isInView));

  const { transform, opacity } = useSpring({
    opacity: isInView ? 1 : 0,
    transform: isInView ? 'translateY(0px)' : 'translateY(100px)',
    from: { transform: 'translateY(100px)', opacity: 0 },
    delay: 800,
  });

  return (
    <StyledWorkBoxWrapper matches={matches}>
      <StyledWorkBox matches={matches}>
        <div>
          <div className="headline">
            <h4>
              {formatMessage({ id: name })}
            </h4>
            {isMobile ? (
              <div className="icon">
                <Icon />
              </div>
            ) : (
              <animated.div
                className="icon"
                style={{
                  filter,
                }}
              >
                <Icon />
              </animated.div>
            )}
          </div>
          {isMobile ? (
            points.map((point) => (
              <p key={point.toString()}>
                {formatMessage({ id: point })}
              </p>
            ))
          ) : (
            <animated.div
              style={{
                transform,
                opacity,
              }}
            >
              {points.map((point) => (
                <p key={point.toString()}>
                  {formatMessage({ id: point })}
                </p>
              ))}
            </animated.div>
          )}
        </div>
      </StyledWorkBox>
    </StyledWorkBoxWrapper>
  );
}

export default WorkBox;
