import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { StyledListItem, StyledMenuItem } from './menu-item.styles';

type Props = {
  shortname: string,
  longname: string,
  anchor: string
};

function MenuItem({
  shortname, longname, anchor,
} : Props) {
  const [onHover, setOnHover] = useState(false);

  const transitions = useTransition(onHover, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: onHover,
    delay: 200,
  });

  return (
    <StyledListItem>
      <StyledMenuItem
        href={`#${anchor}`}
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        onFocus={() => setOnHover(true)}
      >
        {transitions(({ opacity }, item) => (item
          ? (
            <animated.div
              style={{
                position: 'absolute',
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
              }}
            >
              {longname}
            </animated.div>
          )
          : (
            <animated.div
              style={{
                position: 'absolute',
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
              }}
            >
              {shortname}
            </animated.div>
          )))}
      </StyledMenuItem>
    </StyledListItem>
  );
}

export default MenuItem;
