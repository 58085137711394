export const menuItems : Array<{ shortname: string, longname: string, anchor: string }> = [
  {
    shortname: 'STRT',
    longname: 'start',
    anchor: 'start',
  },
  {
    shortname: 'SRVC',
    longname: 'services',
    anchor: 'services',
  },
/*   {
    shortname: 'CMPNY',
    longname: 'Company',
    anchor: 'company',
  }, */
];
