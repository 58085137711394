import { styled } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';

type Props = {
  to: string,
  children: string
};

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#fff',
  '&:hover': {
    textDecoration: 'underline',
  },
  cursor: 'pointer',
});

function FooterLink({ to, children } : Props) {
  return <StyledLink to={to}>{children}</StyledLink>;
}

export default FooterLink;
