import Design from '../images/svg/design.svg';
import Cloud from '../images/svg/cloud.svg';
import Website from '../images/svg/website.svg';

export const workItems = [
  {
    name: 'web',
    icon: Website,
    points: [
      'point-applications',
      'point-websites',
      'point-seo',
    ],
  },
  {
    name: 'design',
    icon: Design,
    points: [
      'point-ux',
      'point-ui',
      'point-ci',
    ],
  },
  {
    name: 'cloud',
    icon: Cloud,
    points: [
      'point-aws',
      'point-kube',
      'point-docker',
    ],
  },
];
