declare module '*.svg' {
  const content: any;
  export default content;
}

export enum Languages {
  DE = 'de',
  EN = 'en',
  CN = 'zh',
}
