import React from 'react';
import styled from '@emotion/styled';

const StyledH2 = styled.h2`
  font-size: 3em;
  text-align: center;
`;

function H2({ children } : { children: string }) {
  return (
    <StyledH2>{children}</StyledH2>
  );
}

export default H2;
