import styled from '@emotion/styled';
import {
  ClickAwayListener, Fade, Grid,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Languages } from '../../custom.d';

const StyledLink = styled.div`
  text-decoration: ${({ active }: { active: boolean }) => (!active ? 'line-through' : 'none')};
  color: #fff;
  &:hover: {
    text-decoration: underline;
  };
  cursor: pointer;
`;

function LangChanger(
  { changeLanguage, currentLang }:
  { changeLanguage: Function, currentLang: Languages },
) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <Grid container direction="column">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <div>
            <Fade in={open}>
              <div>
                <Grid item onClick={() => changeLanguage(Languages.EN)}>
                  <StyledLink active={currentLang === Languages.EN}>
                    English
                  </StyledLink>
                </Grid>
                <Grid item onClick={() => changeLanguage(Languages.DE)}>
                  <StyledLink active={currentLang === Languages.DE}>
                    Deutsch
                  </StyledLink>
                </Grid>
                <Grid item onClick={() => changeLanguage(Languages.CN)}>
                  <StyledLink active={currentLang === Languages.CN}>
                    中文
                  </StyledLink>
                </Grid>
              </div>
            </Fade>
          </div>
          <Grid onClick={() => setOpen(!open)} item>
            <StyledLink active>
              {formatMessage({ id: 'language-short' })}
            </StyledLink>
          </Grid>
        </div>
      </ClickAwayListener>
    </Grid>
  );
}

export default LangChanger;
