import * as React from 'react';
import {
  CssBaseline, Grid, ThemeProvider, useMediaQuery,
} from '@mui/material';
import ReactFullpage, { Item } from '@fullpage/react-fullpage';
import Particles from 'react-tsparticles';
import { Global } from '@emotion/react';
import { useIntl } from 'react-intl';
import FooterLink from '../footer-link';
import { theme } from '../../style/theme';
import MainMenu from '../main-menu';
import Opener from '../opener';
import Work from '../work';
import { particlesInit, particlesOptions } from '../../data/particles';
import { menuItems } from '../../data/menu';
import {
  GlobalStyles, StyledFooterContainer, StyledMain, StyledMenu,
} from '../../style/pages/index.styles';
import Head from '../head';
import LangChanger from '../lang-changer';
import { Languages } from '../../custom.d';

function Home(
  { changeLanguage, currentLang }:
  { changeLanguage: Function, currentLang: Languages },
) {
  const [fullpageState, setFullpageState] = React.useState<string | number>(menuItems[0].anchor);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { formatMessage } = useIntl();

  const onLeave = (origin : Item, destination : Item) : void => {
    setFullpageState(destination.anchor);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Global
        styles={GlobalStyles}
      />
      <Head />
      <div>
        <StyledMain>
          {process.env.NODE_ENV === 'production' && (
            <Particles
              id="tsparticles"
              init={particlesInit}
              options={particlesOptions}
            />
          )}
          <StyledMenu>
            <MainMenu />
          </StyledMenu>
          {process.env.NODE_ENV !== 'test' && (
            <ReactFullpage
              anchors={menuItems.map(({ anchor }) => anchor)}
              onLeave={onLeave}
              render={() => (
                <ReactFullpage.Wrapper>
                  <div className="section">
                    <Opener isInView={menuItems[0].anchor === fullpageState} />
                  </div>
                  <div className="section">
                    <Work isInView={menuItems[1].anchor === fullpageState} />
                  </div>
                  <div />
                </ReactFullpage.Wrapper>
              )}
            />
          )}
          <StyledFooterContainer isMobile={isMobile}>
            <Grid container spacing={isMobile ? 1.5 : 3} alignItems="flex-end">
              <Grid item>
                <LangChanger changeLanguage={changeLanguage} currentLang={currentLang} />
              </Grid>
              <Grid item><FooterLink to="/data-privacy">{formatMessage({ id: 'data-privacy' })}</FooterLink></Grid>
              <Grid item><FooterLink to="/imprint">{formatMessage({ id: 'imprint' })}</FooterLink></Grid>
            </Grid>
            <div className="faded-bg" />
          </StyledFooterContainer>
        </StyledMain>
      </div>
    </ThemeProvider>
  );
}

export default Home;
